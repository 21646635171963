<template>
  <b-form @submit.prevent>
    <b-card>
      <b-card-body>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-row>
              <b-col sm="12" md="6" class="">
                <label>Category</label>
                <inputvselect
                  name="subscription"
                  :label="(reldata) => reldata.name"
                  :valuex="appData.subscriptionid"
                  keys="key"
                  @updatedata="(val) => updatedetails('subscription', val)"
                  :options="subscriptiondata"
                  id="subscription"
                  validations="required"
                ></inputvselect>
              </b-col>
              <b-col sm="12" md="6" class="">
                <inputtext
                  name="Provider"
                  tooltip="Enter Provider "
                  label="Provider"
                  placeholder="Enter Provider"
                  :valuex="appData.subprovider"
                  @updatedata="(val) => (appData.subprovider = val)"
                  validations="required"
                ></inputtext>
              </b-col>
              <b-col sm="12" md="6" class="">
                <label> Status</label>
                <inputvselect
                  name="Status"
                  :label="(reldata) => reldata.name"
                  class=""
                  tooltip="Status"
                  :valuex="appData.status"
                  keys="key"
                  @updatedata="(val) => (appData.status = val.id)"
                  :options="[
                    {
                      id: 0,
                      name: 'Disabled',
                    },
                    {
                      id: 1,
                      name: 'Active',
                    },
                  ]"
                  id="status"
                ></inputvselect>
              </b-col>
            </b-row>

            <div class="d-flex float-right mt-2">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="mr-1"
                variant="secondary"
                @click="$router.go(-1)"
              >
                <feather-icon icon="ArrowLeftIcon" class="mr-25" />
                <span class="align-middle">Back</span>
              </b-button>
              <b-button
                v-if="edittype == 1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                :to="{
                  name: GENAPP_APP_STORE_MODULE_NAME + '-view',
                  params: { id: iddata },
                }"
              >
                <feather-icon icon="EyeIcon" class="mr-25" /> View
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary                            "
                class="mr-1"
                type="submit"
              >
                <feather-icon icon="Edit3Icon" class="mr-25" /> Save
              </b-button>
            </div>
          </b-form>
          <!--/ form -->
        </validation-observer>
      </b-card-body>
    </b-card>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BFormDatepicker,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormFile,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import inputvselect from "@/views/Component/Input/inputvselect.vue";
import inputselect from "@/views/Component/Input/inputselect.vue";
import inputtext from "@/views/Component/Input/inputtext.vue";
import inputtextarea from "@/views/Component/Input/inputtextarea.vue";
import { required, alphaNum } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import StoreModule from "./StoreModule";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
export default {
  components: {
    BCol,
    BCard,
    BRow,
    BForm,
    flatPickr,
    BCardBody,
    BFormGroup,
    inputselect,
    inputvselect,
    inputtextarea,
    inputtext,
    BFormCheckbox,
    BFormInput,
    BFormFile,
    BFormInvalidFeedback,
    BButton,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
    };
  },
  setup(props, { emit }) {
    const GENAPP_APP_STORE_MODULE_NAME =
      "metadata-subscriptionssub-subscriptionssub";
    const modulename = "Subscriptionssub";
    const modulenamesub = "subscriptionssub";
    // Register module
    if (!store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
      store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
    const toast = useToast();
    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });

    let key = 0;
    let edittype = 0;
    let storetype = "addData";
    let iddata = router.currentRoute.params.id;
    if (iddata != undefined) {
      edittype = 1;
      storetype = "editData";
    }
    const blankAppData = {
      name: "",
      subscriptionname: "",

      subscriptionid: "",
      subprovider: "",

      status: 1,
    };
    const appData = ref(JSON.parse(JSON.stringify(blankAppData)));
    var tempcatch = 1;

    const onSubmit = () => {
      let temp = appData.value;
      let appdatax = {
        name: temp.subprovider,
        subscriptionname: temp.subscriptionname,
        subscriptionid: temp.subscriptionid,
        subprovider: temp.subprovider,
        status: temp.status,
      };
      var jx = {
        data: appdatax,
        name: appdatax.subprovider,
        subscriptionname: appdatax.subscriptionname,

        status: appdatax.status,
      };
      if (edittype == 1) {
        jx["id"] = router.currentRoute.params.id || null;
      }
      if (tempcatch) {
        tempcatch = 0;
        store
          .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/" + storetype, jx)
          .then((response) => {
            if (response.data.success) {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  text:
                    modulename +
                    (edittype == 1 ? " editted" : " added") +
                    " successfully",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              router
                .push({
                  name: GENAPP_APP_STORE_MODULE_NAME,
                  params: {
                    id: response.data.id,
                  },
                })
                .catch(() => {});
            } else if (response.data.success == 0) {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  variant: "error",
                },
              });
              router
                .push({
                  name: GENAPP_APP_STORE_MODULE_NAME,
                  params: {
                    id: response.data.id,
                  },
                })
                .catch(() => {});
            }
          })
          .catch((error) => {
            tempcatch = 1;

            if (error.response.status === 404) {
              appData.value = undefined;
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  text: "Couldnt find " + modulenamesub + " details",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          });
      }
    };
    const scriptionssubdata = ref([]);
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchesubscriptionssub")
      .then((response) => {
        scriptionssubdata.value = response.data.data;
      });

    const subscriptiondata = ref([]);
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchAdminDatas")
      .then((response) => {
        subscriptiondata.value = response.data.data;
      });

    if (iddata != undefined) {
      store
        .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchData", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          if (!response.data.data) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Couldnt find " + modulenamesub + " details",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            router
              .push({
                name: GENAPP_APP_STORE_MODULE_NAME,
              })
              .catch(() => {});
          }
          appData.value = response.data.data.data;
          appData.value.status = response.data.data.status;
          appData.value.name = response.data.data.name;
          key = Math.random();
        })
        .catch((error) => {
          if (error.response.status === 404) {
            appData.value = undefined;
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Couldnt find " + modulenamesub + " details",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else if (error.response.status === 500) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Input fields missing values",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else if (error.response.status === 422) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Please refresh your page",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    }
    const resetappData = () => {
      appData.value = JSON.parse(JSON.stringify(blankAppData));
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetappData);
    return {
      GENAPP_APP_STORE_MODULE_NAME,
      edittype,
      iddata,
      appData,
      modulename,
      modulenamesub,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      tempcatch,
      key,
      scriptionssubdata,
      subscriptiondata,
    };
  },
  mounted() {},
  methods: {
    updatedetails(tit, val) {
      console.log(tit, val);
      if (tit == "subscription") {
        this.appData.subscriptionid = val.id;
        this.appData.subscriptionname = val.name;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
